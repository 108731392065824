$theme-colors: (
  "primary": #7b9ecb,
  "secondary": #e9f1fb,
  "success": #aed44f,
  "warning": #eb6f08,
  "danger": #b42e3c,
  "info": #abbfdd,
  "light": #f8f8f8,
  "dark": #2f2f2f,
);

body {
  height: 100vh;
  padding: 0px;
  margin: 0px;
}
